<template>
  <div>
    <v-row>
      <v-col>
        <app-number-field
          v-model="amount"
          background-color="grey lighten-4"
          solo
          elevation="0"
          flat
          height="70px"
          :value="23.22"
          label="Adicionar pagamento"
          style="font-size: 40px"
        />
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col
        v-for="(paymentMethod, index) in paymentMethods"
        :key="index"
        md="4"
      >
        <v-btn
          class="text-capitalize"
          color="primary"
          outlined
          block
          height="60px"
          @click="handleAddPayment(paymentMethod)"
        >
          <div>
            <div>
              <v-icon v-text="paymentMethod.icon" />
            </div>
            <div>
              <b>{{ paymentMethod.name }}</b>
            </div>
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <template v-for="(row, index) in salePayments">
      <v-row :key="index">
        <v-col>
          <v-icon v-text="row.payment_method.icon" />
          {{ row.payment_method.name }}
        </v-col>
        <v-col class="text-right">
          <b>R$ {{ $format.decimal(row.value) }}</b>
          <v-btn icon @click="handleRemovePayment(index)">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    amount: 0,
    paymentMethods: [],
  }),

  computed: {
    salePayments() {
      return this.$store.state.pdv.sale.sale_payments;
    },

    salePaymentsTotal() {
      return this.$store.getters["pdv/salePaymentsTotal"];
    },

    netTotal() {
      return this.$store.getters["pdv/netTotal"];
    },

    paymentRemainder() {
      return this.netTotal - this.salePaymentsTotal;
    },

    isFullyPaid() {
      return this.paymentRemainder == 0;
    },
  },

  created() {
    this.$http.index("payment-methods/payment-methods").then((response) => {
      this.paymentMethods = response.paymentMethods;
    });
  },

  methods: {
    reset() {
      this.$store.commit("pdv/resetPayments");
      this.setAmount()
    },

    setAmount() {
      this.amount = this.paymentRemainder
    },

    handleAddPayment(paymentMethod) {
      let payment = {
        payment_method: paymentMethod,
        value: this.amount,
      };

      this.$store.commit("pdv/addPayment", payment);
      
      this.setAmount()
    },
    
    handleRemovePayment(index) {
      this.$store.commit('pdv/removePayment', index)

      this.setAmount()
    }
  },
};
</script>

<style>
</style>