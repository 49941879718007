<template>
  <div class="text-center">
    <h1 class="text-center mb-4 teal--text text--lighten-4">
      Selecionar Empresa
    </h1>
    <v-list class="mt-10" color="#222e3f" dark>
      <template v-for="company in companies">
        <v-list-item @click="companyLogin(company)">
          <v-list-item-avatar color="grey lighten-1">
            <v-icon>mdi-store</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="text-left">
            <v-list-item-title >
              {{ company.name }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ company.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      companies: [],
    };
  },

  created() {
    this.loadCompanies();
  },

  methods: {
    loadCompanies() {
      this.$http.index("company/company-user").then((response) => {
        this.companies = response.companies;
        // this.products = response.products;
        // this.loading = false;
      });
    },

    async companyLogin(company) {
      this.$auth.accountLogin(company).then(async (response) => {
        await this.$auth.getLoginData();
        this.$router.push("/");
      });
    },
  },
};
</script>

 