<template>
  <v-data-table
    :headers="headers"
    :items="sales.data"
    :items-per-page="20"
    hide-default-footer
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:[`item.name`]="{ item }">
      <div class="d-flex align-center py-2">
        <div>
          <v-icon class="mr-2" color="primary">mdi-account</v-icon>
        </div>
        <div>
          <span v-if="item.customer_id">
            {{ item.customer.name }}
          </span>
          <span v-else>
            Consumidor Final
          </span>
          <br />
          <small class="text--secondary">Código: #{{ item.code }}</small>
        </div>
      </div>
    </template>
    <template v-slot:[`item.created_at`]="{ item }">
      {{ $format.dateTimeBr(item.created_at) }}
    </template>

    <template v-slot:[`item.payment_methods`]="{ item }">
      <!-- <div v-for="(payment, index) in item.sale_payments" :key="index">
      </div> -->
      <v-icon
        v-for="(payment, index) in item.sale_payments"
        :key="index"
        class="mr-2"
        v-text="payment.payment_method.icon"
      />
    </template>
    <template v-slot:[`item.net_total`]="{ item }">
      {{ $format.decimal(item.net_total) }}
    </template>
    <template v-slot:[`item.payment_status`]="{ item }">
      <OrderPaymentStatusChip :value="item.payment_status" />
    </template>
    <template v-slot:loading>Carregando.. </template>
    <template v-slot:no-data>Nenhum item encontrado</template>
  </v-data-table>
</template>

<script>
import OrderPaymentStatusChip from "@/components/orders/sections/OrderPaymentStatusChip";
export default {
  props: {
    sales: {},
  },

  components: {
    OrderPaymentStatusChip,
  },

  data() {
    return {
      headers: [
        {
          text: "Cliente",
          align: "start",
          value: "name",
        },
        { text: "Data", value: "created_at" },
        { text: "Pagamento", value: "payment_methods", align: "center" },
        { text: "Valor", value: "net_total", align: "end" },
        { text: "Status", value: "payment_status", align: "center" },
      ],
    };
  },
};
</script>

<style>
</style>