<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right" cols="12" md="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleStoreCustomer(id = null)"
          >Novo</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="customers.data"
          :loading="loading"
          :server-items-length="customers.total"
          :options.sync="options"
          hide-default-footer
          @update:options="handleTableUpdate($event)"
          @click:row="handleStoreCustomer($event.id)"
        >
          <!-- :items-per-page="20" -->
          <!-- :options.sync="options" -->
          <template v-slot:[`item.phone_wa`]="{ item }">
            <v-icon class="mr-2" color="primary">mdi-whatsapp</v-icon> {{ item.phone_wa }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center py-2">
              <div>
                <v-icon class="mr-2" color="primary">mdi-account</v-icon>
              </div>
              <div>
                {{ item.name }}
                <br>
                <small class="text--secondary">Código: #{{ item.code }}</small>
              </div>
            </div>
          </template>
          <template v-slot:loading>Carregando.. </template>
          <template v-slot:no-data>Nenhum item encontrado</template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="searchParams.page"
            :length="customers.last_page"
            @input="select($event)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <StoreCustomerForm ref="StoreCustomerForm" @store="select(searchParams.page)"/>
  </div>
</template>

<script>
import StoreCustomerForm from "@/components/Customers/forms/StoreCustomerForm.vue";

export default {
  components: { StoreCustomerForm },

  data: () => ({
    headers: [
      { text: "Nome", align: "start", value: "name" },
      { text: "Whatsapp", align: "start", value: "phone_wa" },
      { text: "Endereço", align: "start", value: "main_address.address" },
      { text: "A Receber", align: "left", value: "total_bill_receivebles" },
      { text: "Ticket Médio", align: "left", value: "avg_sales" },
      { text: "Qnt. Vendas", align: "left", value: "count_sales" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
    },

    customers: {
      data: [],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    async select(page = 1) {
      this.loading = true;

      this.searchParams.page = page;

      await this.$http
        .index("customer/customer", this.searchParams)
        .then((response) => {
          this.customers = response.customers;
        });

      this.loading = false;
    },

    handleTableUpdate(options) {
      this.options = options;
      //this.searchParams.page = options.page
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      console.log(text);
    },

    handleStoreCustomer(id = null) {
      this.$refs.StoreCustomerForm.open(id);
    },
  },
};
</script>

<style>
</style>