<template>
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col>
        <app-text-field v-model="form.name" label="Nome do produto" />
      </v-col>
      <v-col cols="4">
        <app-code-field label="Código" :clear-on-auto-code="isInsert" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <app-text-field label="Código de barras" />
      </v-col>
      <v-col>
        <app-text-field label="Código Externo" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <app-number-field label="Custo" />
      </v-col>
      <v-col>
        <app-number-field
          v-model="form.product_variants[0].price"
          label="Preço de Venda"
        />
      </v-col>
    </v-row>
    <h3 class="mt-10 mb-6">Informações adicionais</h3>
    <v-row>
      <v-col>
        <category-search v-model="form.category" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <app-text-field label="Marca" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea v-model="form.description" label="Descrição" rows="4" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-select label="Vender por" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import CategorySearch from "@/components/categories/sections/CategorySearch.vue";

export default {
  components: {
    CategorySearch,
  },
  props: {
    value: {},
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },
  },
  watch: {
    value() {
      this.form = this.value;
    },
    form: {
      handler() {
        this.$emit("input", this.form);
      },

      deep: true,
    },
  },

  data() {
    return {
      form: {
        name: null,
        code: null,
        category: null,
        brand: null,
        description: null,
        sell_by: null,
        manage_quantity: true,

        product_variants: [
          {
            variant_name: null,
            ean: null,
            gtin: null,
            cost: 0,
            price: 0,
            stock: {
              quantity: 0,
            },
          },
        ],
      },
    };
  },
};
</script>

<style></style>
