<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="12" md="8">
        <product-selection-section />
      </v-col>
      <v-divider class="mx-4" vertical />
      <v-col class="mr-2">
        <v-row>
          <v-col>
            <CustomerSelectDrawer />
          </v-col>
        </v-row>
        <template v-if="hasProducts">
          <SaleProductList
            @sale-product-click="handleOrderProductClick($event)"
          />
          <v-row class="mt-2 mb-4">
            <v-col class="text-right">
              <v-btn
                class="text-capitalize"
                text
                secondary
                @click="handleStoreOrder()"
              >
                Salvar Pedido
              </v-btn>
              <v-btn
                class="text-capitalize mt-1"
                large
                block
                color="primary"
                @click="handleProceedToPayment()"
              >
                Finalizar
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row style="margin-top: 100px">
            <v-col class="text-center">
              <h2 class="text--disabled">Carrinho vazio...</h2>
              <v-icon class="text--disabled" size="100"
                >mdi-basket-plus-outline</v-icon
              >
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <v-navigation-drawer
      clipped
      temporary
      app
      right
      v-model="drawer2"
      width="600px"
    >
      <!-- <v-card> -->
      <v-card-title>Adicionar pagamento</v-card-title>
      <v-card-text class="mb-6">
        <SalePaymentsSection ref="SalePaymentsSection" />
      </v-card-text>

      <template v-slot:append>
        <div class="pa-4" style="background-color: #f5f5f5">
          <v-btn
            class="text-capitalize mr-2"
            color="primary"
            @click="handleStoreSale()"
            >Finalizar</v-btn
          >
          <v-btn @click="dialog = false" text class="text-capitalize"
            >Cancelar</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>

    <SaleProductForm ref="SaleProductForm" />
    <CompletedSaleDialog ref="CompletedSaleDialog" />
  </div>
</template>

<script>
import ProductSelectionSection from "@/components/pdv/sections/ProductSelectionSection";
import SaleProductList from "@/components/pdv/sections/SaleProductList";
import SaleProductForm from "@/components/pdv/forms/SaleProductForm";
import SalePaymentsSection from "@/components/pdv/sections/SalePaymentsSection";
import ShiftStatusLabel from "@/components/account/section/ShiftStatusLabel.vue";
import CustomerSelectDrawer from "@/components/Customers/ui/CustomerSelectDrawer";
import CompletedSaleDialog from "@/components/pdv/dialogs/CompletedSaleDialog.vue";

export default {
  components: {
    ProductSelectionSection,
    SaleProductList,
    SalePaymentsSection,
    SaleProductForm,
    ShiftStatusLabel,
    CustomerSelectDrawer,
    CompletedSaleDialog,
  },

  data: () => ({
    drawer2: false,
    drawer: false,
  }),

  computed: {
    hasProducts() {
      return this.$store.getters["pdv/hasProducts"];
    },

    lastShift() {
      return this.$store.getters["accounts/isLastShift"];
    },

    account() {
      return this.$store.state.accounts.selectedAccount;
    },
  },

  methods: {
    selectProduct() {},

    handleOrderProductClick(index) {
      this.$refs.SaleProductForm.edit(index);
    },

    handleProceedToPayment() {
      this.$refs.SalePaymentsSection.reset();
      this.drawer2 = true; // todo chante this
    },

    handleStoreOrder() {
      this.$store.dispatch("pdv/postOrder").then((response) => {
        // this.$refs.CompletedSaleDialog.open();
      });
    },
    
    handleStoreSale() {
      this.$store.dispatch("pdv/postSale", this.account).then((response) => {
        this.$refs.CompletedSaleDialog.open();
      });
    },
  },
};
</script>

<style>
</style>