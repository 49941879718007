<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="dialog"
    width="800px"
  >
    <!-- <v-card> -->
    <v-card-title>{{title}}</v-card-title>
    <v-card-text class="mb-6">
      <v-form ref="form" v-model="valid" lazy-validation>
        <h3 class="mt-4 mb-4">Informações</h3>

        <v-row>
          <v-col>
            <app-text-field v-model="form.name" label="Nome da Categoria" />
          </v-col>

          <v-col cols="6">
            <app-code-field
              label="Código"
              v-model="form.code"
              :clear-on-auto-code="isInsert"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <v-switch
              :label="statusText[form.status]"
              v-model="form.status"
              true-value="active"
              false-value="inactive"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          @click="handleSave()"
          class="text-capitalize mr-2"
          color="primary"
          >Salvar</v-btn
        >
        <v-btn @click="dialog = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>
      

<script>
export default {
  data() {
    return {
      dialog: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      valid: true,
      form: {},
      statusText: {
        active: "Este cadastro está ativo",
        inactive: "Este cadastro está inativo",
      },
      baseForm: {
        code: null,
        name: null,
        status: "active",
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
    title(){
      if (this.isInsert) {
        return  'Cadastrar Categoria'
      }
      if (!this.isInsert) {
        return  'Atualizar Categoria'
      }
    }    
  },

  created() {
    this.reset();
  },

  methods: {
    async open(data = null) {
      this.reset();
      if (data) {
        await this.showCategory(data.id);
      }
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async showCategory(id) {
      await this.$http.$get("product/category/" + id).then((response) => {
        this.form = response.category;
      });
    },

    handleSave() {
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("product/category", this.form).then((response) => {
        this.processSaved(response);
      });
    },

    update() {
      this.$http
        .update("product/category", this.form.id, this.form)
        .then((response) => {
           this.processSaved();
        });
    },

    processSaved(category) {
      this.$emit("store", category);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>


<!--template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Nova Categoria</span>
      </v-card-title>
      <v-card-text>
        <app-text-field label="Nome da Categoria" v-model="form.text" />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="dialog = false" class="text-capitalize" color="error"
          >Cancelar</v-btn
        >
        <v-btn @click="dialog = false" class="text-capitalize" color="success"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</-template>

<script>
export default {
  components: {},
  data() {
    return {
      dialog: false,
      form: {},
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>