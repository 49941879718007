<template>
  <v-form ref="form" lazy-validation>
    <v-row class="text-center" justify="center">
      <v-col cols="4">
        <app-number-field
          outlined
          :value="stock.balance"
          label="Estoque Atual"
          persistent-hint
          @input="setStock($event)"
          :hint="stockInfo"
        />
      </v-col>
    </v-row>
    <!-- <v-row>
              <v-col>
                <v-data-table
                  hide-default-header
                  hide-default-footer
                  dense
                  disable-sort
                  :headers="headers"
                  :items="form.product_variants[0].stock_transaction"
                />
              </v-col>
            </v-row> -->
  </v-form>
</template>

<script>
import CategorySearch from "@/components/categories/sections/CategorySearch.vue";

export default {
  components: {
    CategorySearch,
  },
  props: {
    value: {},
  },

  watch: {
    value() {
      this.stock = this.value;
    },
  },

  data() {
    return {
      stockInfo: null,
      stock: {
        quantity: 0,
        type: null,
        status: null,
        origin: null,
      },
    };
  },

  mounted() {
    this.stockInfo = null;
  },

  methods: {
    setStock(quantity) {
      this.stock.quantity = quantity;
      if (this.stock.balance > quantity) {
        this.stockInfo = "Saida de " + (this.stock.balance - quantity);
      }
      if (this.stock.balance < quantity) {
        this.stockInfo =
          "Entrada de " + Math.abs(this.stock.balance - quantity);
      }
    },
  },
};
</script>

<style></style>
