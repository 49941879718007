<template>
  <div>
    <template v-if="selectedValue">
      <v-text-field
        :label="label"
        :value="selectedValue.name"
        @click="open()"
        @keypress="open()"
        prepend-inner-icon="mdi-account"
        solo
        flat
        readonly
        background-color="grey lighten-4"
        hide-details="auto"
        clearable
        @click:clear="clear()"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <v-text-field
          readonly
          :label="label"
          @click="open()"
          prepend-inner-icon="mdi-account"
          solo
          flat
          background-color="grey lighten-4"
          hide-details="auto"
        />
      </div>
    </template>

    <v-navigation-drawer
      clipped
      temporary
      app
      right
      v-model="drawer"
      width="600px"
    >
      <!-- <v-card> -->
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar {{ label }}</span>
            </div>

            <v-btn @click="openStoreCustomerForm()" color="success" icon
              ><v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            :loading="isLoading"
            class="mt-4"
            v-model="searchForm.searchText"
            autofocus
            solo
            flat
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-magnify"
            placeholder="Pesquisar..."
            hide-details
            v-debounce:300ms="search"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-list>
            <template v-for="(row, index) in searchResults.data">
              <v-list-item :key="index" @click="setSelectedValue(row)">
                <v-list-item-content>
                  <v-list-item-title>{{ row.name }} </v-list-item-title>
                  <!-- <v-list-item-title v-text="row.name"></v-list-item-title>

                  <v-list-item-title v-text="row.code"></v-list-item-title> -->
                </v-list-item-content>
                <v-list-item-icon> </v-list-item-icon>
              </v-list-item>
              <v-divider :key="'div' + index" />
            </template>
          </v-list>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize" text @click="drawer = false"
            >Voltar</v-btn
          >
        </v-card-actions>
      </v-card> -->
    </v-navigation-drawer>
    <StoreCustomerForm
      @store="setSelectedValue($event)"
      ref="StoreCustomerForm"
    />
  </div>
</template>

<script>
import StoreCustomerForm from "../forms/StoreCustomerForm";
export default {
  components: {
    StoreCustomerForm,
  },
  props: {
    value: {},
  },

  data: () => ({
    label: "Cliente",
    url: "customer/customer",

    searchResults: {
      data: [],
    },

    selectedValue: {
      name: null,
    },

    drawer: false,

    isLoading: false,

    searchForm: {
      searchText: null,
    },
  }),

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },

  methods: {
    async open() {
      await this.search();

      this.drawer = true;
    },

    async search() {
      this.isLoading = true;

      await this.$http.index(this.url, this.searchForm).then((response) => {
        this.searchResults = response.customers;
      });

      this.isLoading = false;
    },

    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$store.commit("pdv/addCustomer", selectedValue);

      this.drawer = false;
    },

    clear() {
      this.setSelectedValue(null);
    },

    openStoreCustomerForm() {
      this.$refs.StoreCustomerForm.open();
    },
  },
};
</script>
