<template>
  <!-- <div>
    <h5 class="mb-0">{{ getShiftText(shift) }}</h5>
    <small class="text-muted">{{ getStartedAtText(shift) }}</small>
    <template v-if="!isShiftOpen(shift)">
      <small class="text-muted"> | {{ getEndedAtText(shift) }}</small>
    </template>
  </div> -->
  <v-alert dense color="grey lighten-4">
    <template v-slot:prepend>
      <v-icon color="primary" large class="ml-2 mr-4">mdi-cash-register</v-icon>
    </template>
    {{ getShiftText(shift) }}
    <br>
    <small class="text--secondary">{{ getStartedAtText(shift) }}</small>
  </v-alert>
</template>

<script>
import Shift from "@/mixins/Account/Shift";

export default {
  props: {
    shift: {},
  },

  mixins: [Shift],
};
</script>

<style>
</style>