<template>
  <div class="ml-4">
    <v-row>
      <v-col cols="12" md="6">
        <span class="title">
          <v-icon class="mr-2">mdi-folder-account-outline</v-icon>Informações da
          Empresa
        </span>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-checkbox
        class="mr-10"
        v-model="baseForm.person_type"
        value="juridica"
        label="Pessoas Jurídica"
      />

      <v-checkbox
        v-model="baseForm.person_type"
        value="fisica"
        label="Pessoa Física"
      />
    </v-row>

    <template v-if="baseForm.person_type == 'juridica'">
      <v-row>
        <v-col cols="12" md="4">
          <app-text-field v-model="baseForm.name" label="Razão social" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <app-text-field v-model="baseForm.alias" label="Nome Fantasia" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <app-cnpj-field v-model="baseForm.cnpj" label="Cnpj" />
        </v-col>
      </v-row>
    </template>
    <template v-if="baseForm.person_type == 'fisica'">
      <v-row>
        <v-col cols="12" md="4">
          <app-text-field v-model="baseForm.name" label="Nome" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <app-text-field v-model="baseForm.alias" label="Apelido" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <app-cpf-field v-model="baseForm.cpf" label="Cpf" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <app-rg-field v-model="baseForm.rg" label="Rg" />
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col cols="12" md="4">
        <app-text-field
          :disabled="baseForm.inscricao_estadual_isento == 1"
          v-model="baseForm.inscricao_estadual"
          label="Inscrição Estadual"
        />

        <v-checkbox
          class="mr-10"
          v-model="baseForm.inscricao_estadual_isento"
          :true-value="1"
          :false-value="0"
          label="Inscrição estadual Isento"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <span class="title">
          <v-icon class="mr-2">mdi-map-legend</v-icon>Endereço
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <app-text-field v-model="baseForm.address" label="Endereço" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <app-text-field v-model="baseForm.number" label="Numero" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <app-text-field v-model="baseForm.complement" label="Complemento" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <app-text-field v-model="baseForm.district" label="Bairro" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <app-text-field v-model="baseForm.zipcode" label="CEP" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <span class="title">
          <v-icon class="mr-2">mdi-phone</v-icon>Contatos
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" cols="12">
        <app-text-field v-model="baseForm.phone_wa" label="Telefones" />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="4" cols="12">
        <app-text-field v-model="baseForm.email1" label="Emails" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" cols="12">
        <app-text-field v-model="baseForm.website" label="Site" />
        <v-row>
          <v-col cols="12" class="text-right">
            <br />
            <v-btn
              @click="handleSave()"
              class="ml-2 text-capitalize"
              color="success"
              >salvar</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},

  data: () => ({
    dialog: false,
    value: 0,

    city: null,

    nfeSettings: {
      ambiente_nfe: null,
      csc: null,
      csc_id: null,
    },

    baseForm: {
      id: null,
      name: null,
      alias: null,
      person_type: null,
      cpf: null,
      rg: null,
      cnpj: null,
      inscricao_estadual: null,
      inscricao_estadual_isento: 0,
      status: null,
      mercado_pago_access_token: null,
      mercado_pago_public_key: null,
      mercado_pago_refresh_token: null,
      created_at: null,
      updated_at: null,
      pivot: null,
    },
  }),

  mounted() {
    this.setData();
  },

  computed: {
    company() {
      return this.$store.state.auth.user.company;
    },
  },

  methods: {
    setData() {
      this.baseForm = JSON.parse(JSON.stringify(this.company));
    },
    handleSave() {
      this.$http
        .update("company/company", this.baseForm.id, this.baseForm)
        .then((response) => {
         })
        .catch((error) => {});
    },
  },
};
</script>

<style>
</style>
