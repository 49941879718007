<template>
  <div>
    <template v-if="value == 'paid'">
      <v-icon small color="primary">mdi-checkbox-marked-circle</v-icon>
      <span class="ml-1 primary--text">Pago</span>
    </template>
    <template v-if="value == 'waiting'">
      <v-icon small color="warning">mdi-clock</v-icon>
      <span class="ml-1 warning--text">Aguardando</span>
    </template>
    <template v-if="value == 'unpaid'">
      <v-icon small color="error">mdi-close-circle</v-icon>
      <span class="ml-1 error--text">Não pago</span>
    </template>
    <template v-if="value == 'c'">
      <v-icon small color="error">mdi-close-circle</v-icon>
      <span class="ml-1 error--text">Cancelado</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
};
</script>

<style></style>
