<template>
  <div>
    <h1 class="text-center mb-4 teal--text text--lighten-4">Recuperar senha</h1>

    <v-row>
      <v-col>
        <v-col md="12">
          <v-text-field
            color="primary"
            v-model="form.email"
            placeholder="exemplo@exemplo.com"
            dark
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col>
        <v-btn
          class="text-capitalize"
          @click="resetPassword()"
          block
          color="primary"
        >
          Recuperar senha
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form : {
        email : null
      }
    };
  },

  methods: {
    resetPassword() {
      this.$http.$post('auth/recover-password', this.form).then(response => {
        // todo show 
      })
    }
  },
};
</script>

 