import { format } from 'date-fns'
export default {

  methods: {

    isShiftOpen(shift) {
      if (!shift) {
        return false
      }

      if (shift.status == 'open') {
        return true
      }
    },

    getShiftText(shift) {

      if (this.isShiftOpen(shift)) {
        return 'Caixa Aberto'
      }
      return 'Caixa Fechado'

    },

    getStartedAtText(shift) {
      if (shift) {
        let text = 'Aberto por ' + shift.started_by_user.name + ' em ' + this.$format.dateTimeBr(shift.started_at)
        return text
      }
    },

    getEndedAtText(shift) {
      if (shift) {
        let text = 'Fechado por ' + shift.ended_by_user.name + ' em ' + this.$format.dateTimeBr(shift.ended_at)
        return text
      }
    },

  }

}