<template>
  <div>
    <h1 v-if="tokenInvalid" class="text-center mb-4 teal--text text--lighten-4">
      Convite Invalido ou expirado.
    </h1>
    <v-form v-if="!tokenInvalid" ref="form" v-model="valid" lazy-validation>
      <h1 class="text-center mb-4 teal--text text--lighten-4">
        Junte-se à {{ form.company.name }}
      </h1>
      <v-row>
        <v-col>
          <v-col md="12">
            <app-text-field
              dark
              color="primary"
              label="Seu Nome"
              :rules="requiredName"
              v-model="form.user.name"
            />
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <app-password-field
            dark
            class="mx-3"
            color="primary"
            label="Sua Senha"
            :rules="requiredPassword"
            v-model="form.user.password"
          />
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-col>
          <v-btn
            block
            color="primary"
            @click="validationForm()"
            class="text-capitalize"
          >
            Criar Conta
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      tokenInvalid: false,

      requiredName: [(v) => !!v || "Nome é necessário"],
      requiredPassword: [(v) => !!v || "Senha é necessário"],
      form: {
        company: {},
        user: {},
        invitation_token: null,
      },
    };
  },

  mounted() {
    this.getInvitation();
  },

  methods: {
    getInvitation() {
      this.$http
        .index("accept/invitation", { token: this.$route.params.token })
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.companyUser));
        })
        .catch((error) => {
          this.tokenInvalid = true;
        });
    },

    validationForm() {
      if (this.$refs.form.validate()) {
        this.confirmInvitation();
      }
    },

    confirmInvitation() {
      this.$http.store("accept/invitation", this.form).then((response) => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style></style>
