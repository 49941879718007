<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs>
          <v-tab to="/produtos" class="text-capitalize">Produtos</v-tab>
          <v-tab to="/categorias" class="text-capitalize">Categorias</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleNewCategory()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          @click:row="handleNewCategory($event)"
          :headers="headers"
          :items="categories"
          :items-per-page="50"
          hide-default-footer
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:loading>Carregando.. </template>
          <template v-slot:no-data>Nenhum item encontrado</template>
        </v-data-table>
 
      </v-col>
    </v-row>
    <!-- <store-categories-form ref="StoreCategoriesForm" /> -->
    <store-categories-form @store="select()" ref="StoreCategoriesForm" />
  </div>
</template>

<script>
import StoreCategoriesForm from "../components/categories/forms/StoreCategoriesForm.vue";

export default {
  components: { StoreCategoriesForm },

  data() {
    return {
      loading: false,
      searchParams: {   text: null },
      categories: [],
      headers: [
        {
          text: "Código",
          align: "center",
          value: "code",
          sortable: false,
          width: "5%",
        },
        { text: "Nome", align: "start", value: "name", sortable: false },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "center",
          width: "2%",
        },
      ],
    };
  },

  created() {
    this.select();
  },

  methods: {
    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },
    select( ) {
 
      this.loading = true;
      this.$http
        .index("product/category", this.searchParams)
        .then((response) => {
          this.categories = response.categories;
          this.loading = false;
        });
    },

    handleNewCategory(data) {
      this.$refs.StoreCategoriesForm.open(data);
    },
  },
};
</script>

<style>
</style>