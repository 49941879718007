<template>
  <div>
    <validation-observer #default="{ invalid }" ref="signupValidate">
      <form @submit.prevent>
        <validation-provider
          name="name"
          rules="required|min:3"
          #default="{ errors }"
        >
          <v-row class="mt-6">
            <v-col md="12">
              <v-text-field
                color="primary"
                @keyup.enter.native="login"
                v-model="form.user_name"
                placeholder="Seu Nome"
                dark
                :state="errors.length > 0 ? false : null"
                :error-messages="errors[0]"
              />
            </v-col>
          </v-row>
        </validation-provider>

        <validation-provider
          name="companyName"
          rules="required|min:3"
          #default="{ errors }"
        >
          <v-row class="mt-6">
            <v-col md="12">
              <v-text-field
                color="primary"
                @keyup.enter.native="login"
                v-model="form.company_name"
                placeholder="Nome da Sua Empresa"
                label="Nome da sua empresa"
                dark
                :state="errors.length > 0 ? false : null"
                :error-messages="errors[0]"
              />
            </v-col>
          </v-row>
        </validation-provider>

        <validation-provider
          name="email"
          rules="required|email"
          #default="{ errors }"
        >
          <v-row class="mt-6">
            <v-col md="12">
              <v-text-field
                color="primary"
                @keyup.enter.native="login"
                v-model="form.email"
                placeholder="exemplo@exemplo.com"
                dark
                :state="errors.length > 0 ? false : null"
                :error-messages="errors[0]"
              />
            </v-col>
          </v-row>
        </validation-provider>

        <v-row class="mt-3">
          <v-col md="6">
            <app-password-field
              color="primary"
              @keyup.enter.native="login"
              v-model="form.password"
              placeholder="Senha"
              dark
              :name="'password'"
            />
          </v-col>
          <v-col md="6">
            <app-password-field
              color="primary"
              @keyup.enter.native="login"
              v-model="form.confirm_password"
              placeholder="Confirmar Senha"
              dark
              :name="'password'"
            />
          </v-col>
        </v-row>
      </form>
    </validation-observer>

    <v-row class="mt-10">
      <v-col class="text-center">
        <v-btn
          class="text-capitalize"
          @click="validationForm()"
          block
          color="primary"
        >
          Criar Conta
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row class="mt-10">
      <v-col class="text-center">
        <v-btn
          class="text-capitalize"
          @click="googleSignup()"
          block
          color="secondary"
        >
          <v-icon class="mr-2" small >mdi-google</v-icon>
          Entrar com google
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "./../../../utils/validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      form: {
        user_name: null,
        company_name: null,
        email: null,
        password: null,
        password_confirm: null,
      },
    };
  },

  methods: {
    validationForm() {
      this.$refs.signupValidate.validate().then((success) => {
        if (success) {
          this.signup();
        }
      });
    },

    signup() {
      //this.$auth.login(this.form);
      this.$http.store("app/signup", this.form).then((response) => {
        this.$router.push("/setup");
      });
    },

    googleSignup() {
      window.location.href = 'http://app.pos.com.br/auth/google/signup';
    }
  },
};
</script>

<style>
</style>