<template>
  <div>
    <v-btn
      v-if="discount == null"
      class="text-capitalize pr-0"
      @click="openDiscountDrawer()"
      :riple="false"
      text
      plain
      secondary
    >
      Adicionar Desconto
    </v-btn>

    <template v-if="discount">
      <v-btn @click="removeDiscount()" icon color="error">
        <v-icon class="error--text">mdi-close-circle-outline</v-icon>
      </v-btn>
      <v-btn
        class="text-capitalize error--text pl-1 pr-0"
        @click="openDiscountDrawer()"
        :riple="false"
        text
        plain
        >Desconto: ({{ discount }}%) R$
        {{ $format.decimal((discount / 100) * netTotal) }}
      </v-btn>
    </template>
    <DiscountDrawer :netTotal="netTotal" ref="DiscountDrawer" />
  </div>
</template>

<script>
import DiscountDrawer from "@/components/pdv/sections/DiscountDrawer";

export default {
  components: {
    DiscountDrawer,
  },
  created() {},
  data() {
    return {};
  },
  computed: {
    saleProducts() {
      return this.$store.state.pdv.sale.sale_products;
    },
    discount() {
      return this.$store.state.pdv.sale.discount;
    },

    netTotal() {
      return this.$store.getters["pdv/netTotal"];
    },
  },
  props: {},
  methods: {
    openDiscountDrawer() {
      this.$refs.DiscountDrawer.open();
    },
    removeDiscount() {
      this.$store.commit("pdv/addDiscount", null);
    },
  },
};
</script>

<style lang="scss" scoped></style>
