<template>
  <div>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item :to="item.link" v-for="(item, i) in items" :key="i">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedItem: 1,
    items: [
      {
        text: "Dados da Empresa",
        icon: "mdi-domain",
        link: "/configuracoes/empresa",
      },
      {
        text: "Integração Mercado Pago",
        icon: "mdi-currency-usd",
        link: "/configuracoes/integracao_mercado_pago",
      },
      // { text: "Audience", icon: "mdi-account" },
      // { text: "Conversions", icon: "mdi-flag" },
    ],
  }),
};
</script>

<style>
</style>