<template>
  <div>
    <v-row class="mt-2">
      <template v-for="product in products.data">
        <v-col cols="2">
          <v-card height="11rem" elevation="1" color="grey lighten-3">
            <v-card-text
              class="d-flex flex-column justify-space-between pb-2"
              style="height: 100%"
            >
              <div></div>
              <div class="text-center">
                {{ product.name }}
              </div>
              <div class="d-flex justify-space-between align-center">
                <div>
                  R$<b>
                    {{ $format.decimal(product.product_variants[0].price) }}</b
                  >
                </div>
                <div>
                  <v-btn
                    class=""
                    fab
                    text
                    small
                    color="primary"
                    @click="
                      handleAddProduct(product, product.product_variants[0])
                    "
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col cols="2">
        <v-card height="11rem" elevation="0" color="grey lighten-4" @click="handleNewProduct()">
          <v-card-text class="d-flex align-center" style="height: 100%">
            <div class="text-center" style="width:100%">
              <v-icon class="text--disabled">mdi-plus</v-icon>
              <br>
              <span class="text--disabled"> Cadastrar Produto</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- product selection form -->
      <!-- <v-data-table
          :headers="headers"
          :items="products.data"
          :items-per-page="20"
          hide-default-footer
        >
          <template v-slot:[`item.value`]="{ item }">
            <span>{{ $format.decimal(item.product_variants[0].price) }}</span>
            <v-btn
              class="ml-4"
              fab
              text
              small
              color="primary"
              @click="handleAddProduct(item, item.product_variants[0])"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </v-data-table> -->
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    products: {},
  },

  data: () => ({
    headers: [
      {
        text: "Produto",
        value: "name",
      },
      { value: "value", align: "end" },
    ],
  }),

  methods: {
    handleAddProduct(product, product_variant) {
      this.$store.commit("pdv/increaseProduct", {
        product: product,
        product_variant: product_variant,
      });
    },

    handleNewProduct() {
      this.$emit('newProduct')
    }
  },
};
</script>

<style>
</style>