<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="drawer"
    width="1000px"
  >
    <!-- <v-card> -->
    <v-card-title>{{title}}</v-card-title>
    <v-card-text class="mb-6">
      <v-row>
        <v-col>
          <v-tabs v-model="tab">
            <v-tab class="text-capitalize">Informações</v-tab>
            <v-tab class="text-capitalize">Estoque</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab">
        <v-card-text class="mb-6">
          <v-tab-item>
            <product-information-form
              v-model="form"
              ref="ProductInformationForm"
            />
          </v-tab-item>
          <v-tab-item>
            <product-stock-form
              v-model="form.product_variants[0].stock"
              ref="ProductStockForm"
            />
          </v-tab-item>
        </v-card-text>
      </v-tabs-items>
    </v-card-text>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          @click="handleSave()"
          class="text-capitalize mr-2"
          color="primary"
          >Salvar</v-btn
        >
        <v-btn @click="drawer = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import StockMixin from "@/mixins/Products/StockMixin";
import ProductInformationForm from "./ProductInformationForm.vue";
import ProductStockForm from "./ProductStockForm.vue";

export default {
  components: { ProductInformationForm, ProductStockForm },
  mixins: [StockMixin],

  data() {
    return {
      stockQuantity: null,
      headers: [
        { value: "quantity" },
        { value: "status" },
        { value: "type" },
        { value: "date" },
        { value: "balance" },
      ],
      drawer: false,
      tab: 0,
      valid: true,
      form: {},

      baseForm: {
        name: null,
        code: null,
        category: null,
        brand: null,
        description: null,
        sell_by: "unity",
        manage_quantity: true,

        product_variants: [
          {
            variant_name: null,
            ean: null,
            gtin: null,
            cost: 0,
            price: 0,
            stock: {
              quantity: 0,
            },
          },
        ],
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
    title(){
      if (this.isInsert) {
        return  'Cadastrar Produto'
      }
      if (!this.isInsert) {
        return  'Atualizar Produto'
      }
    }
  },

  created() {
    this.reset();
  },

  methods: {
    async open(data = null) {
      this.reset();
      if (data) {
        await this.showProduct(data.id);
      }
      this.drawer = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.stockQuantity = null;
    },

    async showProduct(id) {
      await this.$http.$get("product/product/" + id).then((response) => {
        this.form = response.product;
      });
    },

    handleSave() {
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("product/product", this.form).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("product/product", this.form.id, this.form)
        .then((response) => {
          console.log(response);
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.drawer = false;
    },
  },
};
</script>

<style lang="sass" scoped></style>
