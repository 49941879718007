<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="drawer"
    width="400px"
  >
    <v-card-title>Adicionar Desconto</v-card-title>
    <v-card-text class="mb-6">
      <v-row>
        <v-col>
          <app-number-field
            prepend-inner-icon="mdi-percent"
            solo            
            flat
            v-model="discount"
            @input="setDiscount()"
            background-color="grey lighten-4"
            hide-details="auto"
        /></v-col>
      </v-row>
      
      <v-row>
        <v-col>
          <app-number-field
            append-icon="mdi-currency-brl"
            solo
            flat
            @input="setValueDiscount()"
            v-model="valueDiscount"
            background-color="grey lighten-4"
            hide-details="auto"
        /></v-col>
      </v-row>
 
      <v-item-group>
        <v-container>
          <v-row>
            <v-col v-for="(row, index) in discounts" :key="index" cols="6">
              <v-item v-slot="{ active, toggle }">
                <v-card
                  :color="row == discount  ? 'primary' : ''"
                  class="d-flex align-center"
                  height="5rem"
                  @click="selectedDiscount(row), toggle()"
                >
                  <v-scroll-y-transition>
                    <div class="text-h4 flex text-center">{{ row }}%</div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-card-text>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          class="text-capitalize mr-2"
          color="primary"
          @click="addDiscount()"
          >Salvar</v-btn
        >
        <v-btn @click="drawer = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    netTotal: {  },
  },
  data: () => ({
    drawer: false,
    discounts: [5, 10, 15, 20],
    discount: null,
    valueDiscount: null,
  }),

  computed: {},

  // watch: {
  //   discount: {
  //     handler() {
  //     },
    //  this.$store.commit("pdv/addDiscount", this.discount)

  //     deep: true,
  //   },
  // },

  methods: {
    open() {
      this.drawer = true;
    },
    setDiscount() {
      this.valueDiscount =   this.discount / 100 *this.netTotal
    },
    setValueDiscount() {
      this.discount =   this.valueDiscount * 100 /this.netTotal
    },
    selectedDiscount(discount) {
      this.discount =  discount
    },
    addDiscount(){
     this.$store.commit("pdv/addDiscount", this.discount)
     this.drawer = false
    }

            

  },
};
</script>

<style>
</style>