export default {

  // watch: {
  //   stockQuantity: {
  //     handler() {
  //       if (product_variant.balance > value) {

  //     product_variant.     origin= "custom",
  //     product_variant.       type= "out",
  //     product_variant.          status= "processed",
  //         this.stockInfo =
  //           "Saida de " +
  //           (value - product_variant.balance);
  //       }
  //       if (product_variant.balance < value) {

  //     product_variant.     origin = "custom",
  //     product_variant.       type = "out",
  //     product_variant.   status = "processed",
  //         this.stockInfo =
  //           "Entrada de " +
  //           (value - product_variant.balance);
  //       }
  //     },

  //     deep: true,
  //   },
  // },

  methods: {
    calcStock(product_variant, value) {
      if (product_variant.balance > value) {
        (product_variant.origin = "custom"),
          (product_variant.type = "out"),
          (product_variant.status = "processed"),
          (this.stockInfo =
            "Saida de " +
            (value -
              product_variant.balance));
      }
      if (product_variant.balance < value) {
        (product_variant.origin = "custom"),
          (product_variant.type = "in"),
          (product_variant.status = "processed"),
          (this.stockInfo =
            "Entrada de " +
            (value -
              product_variant.balance));
      }
    },



  }
}