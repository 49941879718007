<template>
  <div>
    <v-row>
      <v-col>
        <!-- product selection form -->
        <v-data-table
          :headers="headers"
          :items="products.data"
          :items-per-page="20"
          hide-default-footer
        >
          <template v-slot:[`item.value`]="{ item }">
            <span>{{ $format.decimal(item.product_variants[0].price) }}</span>
            <v-btn
              class="ml-4"
              fab
              text
              small
              color="primary"
              @click="handleAddProduct(item, item.product_variants[0])"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <template v-slot:loading>Carregando.. </template>
          <template v-slot:no-data>Nenhum item encontrado</template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props : {
    products : {}
  },

  data: () => ({
    headers: [
      {
        text: "Produto",
        value: "name",
      },
      { value: "value", align: "end" },
    ],
  }),

  methods: {

    handleAddProduct(product, product_variant) {
      this.$store.commit("pdv/increaseProduct", {
        product: product,
        product_variant: product_variant,
      });
    },
  },
};
</script>

<style>
</style>