var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.sales.data,"items-per-page":20,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-account")])],1),_c('div',[(item.customer_id)?_c('span',[_vm._v(" "+_vm._s(item.customer.name)+" ")]):_c('span',[_vm._v(" Consumidor Final ")]),_c('br'),_c('small',{staticClass:"text--secondary"},[_vm._v("Código: #"+_vm._s(item.code))])])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.created_at))+" ")]}},{key:"item.payment_methods",fn:function(ref){
var item = ref.item;
return _vm._l((item.sale_payments),function(payment,index){return _c('v-icon',{key:index,staticClass:"mr-2",domProps:{"textContent":_vm._s(payment.payment_method.icon)}})})}},{key:"item.net_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.decimal(item.net_total))+" ")]}},{key:"item.payment_status",fn:function(ref){
var item = ref.item;
return [_c('OrderPaymentStatusChip',{attrs:{"value":item.payment_status}})]}},{key:"loading",fn:function(){return [_vm._v("Carregando.. ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v("Nenhum item encontrado")]},proxy:true}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }