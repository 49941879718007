<template>
  <div>
    <h1 class="text-center mb-4 teal--text text--lighten-4">Redefinir senha</h1>

    <v-row>
      <v-row class="mt-3">
        <v-col md="6">
          <app-password-field
            color="primary"
            @keyup.enter.native="login"
            v-model="form.password"
            placeholder="Senha"
            dark
            :name="'password'"
          />
        </v-col>
        <v-col md="6">
          <app-password-field
            color="primary"
            @keyup.enter.native="login"
            v-model="form.confirm_password"
            placeholder="Confirmar Senha"
            dark
            :name="'password'"
          />
        </v-col>
      </v-row>
    </v-row>
    <v-row class="mt-10">
      <v-col>
        <v-btn
          class="text-capitalize"
          @click="resetPassword()"
          block
          color="primary"
        >
          Recuperar senha
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        token : null,
        password: null,
        confirm_password : null
      },
    };
  },

  created() {
    this.form.token = this.$route.params.token
  },

  methods: {
    resetPassword() {
      this.$http.$post("auth/reset-password", this.form).then((response) => {
        // todo show
      });
    },
  },
};
</script>

 