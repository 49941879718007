<template>
  <v-tabs class="mt-2">
    <v-tab @change="$emit('input', null)" class="text-capitalize">Todos</v-tab>
    <v-tab
      class="text-capitalize"
      v-for="(category, index) in categories"
      :key="index"
      @change="$emit('input', category.id)"
    >
      {{ category.name }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data: () => ({
    categories: {
      data: {},
    },
  }),

  created() {
    this.select();
  },

  methods: {
    select(page = 1) {
      this.$http
        .index("product/category", { status: "active" })
        .then((response) => {
          this.categories = response.categories;
        });
    },
  },
};
</script>
