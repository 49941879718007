<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-text style="padding: 20px;" class="text-center">
        <v-icon class="mt-4" color="primary" style="font-size: 70px"
          >mdi-cash-register</v-icon
        >
        <v-card-title class="pt-1 pb-6 d-flex justify-center"
          >Abertura de caixa</v-card-title
        >
        <label class="d-flex justify-start">Informe o valor Inicial</label>
        <app-number-field
          class="mt-2"
          v-model="value"
          append-icon="mdi-currency-brl"
          solo
          flat
          background-color="grey lighten-4"
          hide-details="auto"
        />
      </v-card-text>
      <v-card-actions style="padding-bottom: 20px; padding-top: 0px">
        <v-row>
          <v-col>
            <v-btn @click="dialog = false" text class="text-capitalize" block
              >Cancelar</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="openShift()"
              class="text-capitalize"
              color="primary"
              block
              >Abrir caixa</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      value: 0,
    };
  },

  methods: {
    open() {
      this.dialog = true;
      this.value = 0;
    },
    openShift(account) {
      this.$http
        .store("account/shift", { value: this.value })
        .then((response) => {
          this.$emit("store");
          this.dialog = false;
        });
    },
  },
};
</script>
