<template>
  <div>
    <v-row class="align-center">
      <v-col cols="4">
        <shift-status-label :shift="lastShift" />
      </v-col>
      <v-col class="text-right" cols="8">
        <v-btn class="text-capitalize mr-4" bottom outlined color="primary">
          <v-badge color="secondary" inline content="6">
            Receber Pedido
          </v-badge>
        </v-btn>
        <v-btn class="text-capitalize mr-4" bottom outlined color="primary"
          >Movimentações</v-btn
        >
      </v-col>
    </v-row>
    <v-divider />
    <PdvForm v-if="isSelectedDrawerOpen" />
    <v-container v-if="!isSelectedDrawerOpen">
      <v-row justify="center" no-gutters style="height: 150px;">
        <v-col cols="2">
          <v-btn
            class="text-capitalize "
            large
            color="primary"
            @click="openShift()"
            >Abrir Caixa</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <open-shift-dialog @store="getAccounts()" ref="OpenShiftDialog" />
  </div>
</template>

<script>
import OpenShiftDialog from "@/components/account/section/OpenShiftDialog";
import PdvForm from "@/components/pdv/forms/PdvForm";
import ShiftStatusLabel from "@/components/account/section/ShiftStatusLabel.vue";

export default {
  components: {
    OpenShiftDialog,
    PdvForm,
    ShiftStatusLabel,
  },

  computed: {

    lastShift() {
      return this.$store.getters["accounts/isLastShift"];
    },

    isSelectedDrawerOpen() {
      return this.$store.getters["accounts/isSelectedDrawerOpen"];
    },
  },
  async created() {
    await this.getAccounts();
  },

  methods: {
    openShift() {
      this.$refs.OpenShiftDialog.open();
    },

    async getAccounts() {
      await this.$store
        .dispatch("accounts/accountIndex")
        .then((response) => {});
    },
  },
};
</script>

<style>
</style>