<template>
  <div>
    <template v-for="(row, index) in saleProducts">
      <v-row class="mt-2" :key="index" @click="handleEditOrderProduct(index)">
        <v-col cols="1">
          <b>{{ row.quantity }}</b>
        </v-col>
        <v-col cols="7">
          {{ row.name }}
          <br />
          <small class="text--secondary">
            R$ {{ $format.decimal(row.price) }}</small
          >
        </v-col>
        <v-col cols="4" class="text-right">
          R$
          {{ $format.decimal(row.price * row.quantity - row.product_discount) }}
          <template v-if="row.product_discount > 0">
            <br />
            <small class="error--text"
              >- R${{ $format.decimal(row.product_discount) }} Desc.</small
            >
          </template>

          <v-btn x-small class="ml-4" icon @click="handleRemoveItem(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-divider class="my-6" />

    <v-row>
      <v-col class="text-right">
      <DiscountButton />
        <h1>R$ {{ $format.decimal(netTotal - discountGeneral) }}</h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DiscountButton from "../sections/DiscountButton";
export default {
  data() {
    return {
    };
  },
  components: {
    DiscountButton,
  },
  computed: {
    saleProducts() {
      return this.$store.state.pdv.sale.sale_products;
    },
    discount() {
      return this.$store.state.pdv.sale.discount;
    },

    netTotal() {
      return this.$store.getters["pdv/netTotal"];
    },

    discountGeneral() {
      return this.$store.getters["pdv/discountGeneral"];
    },
  },

  methods: {
    handleRemoveItem(index) {
      this.$store.commit("pdv/removeProduct", index);
    },

    handleEditOrderProduct(index) {
      this.$emit("sale-product-click", index);
    },
  },
};
</script>

<style>
</style>