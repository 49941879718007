<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="3">
        <app-text-search-field v-model="searchParams.text" @input="select()" />
      </v-col>
      <v-col cols="3">
        <app-text-field
          background-color="grey lighten-4"
          label="Data Inicial"
          type="date"
          filled
          @input="select()"
          outlined
          v-model="searchParams.start_issue_date"
        />
      </v-col>
      <v-col cols="3">
        <app-text-field
          background-color="grey lighten-4"
          label="Data Final"
          type="date"
          filled
          @input="select()"
          outlined
          v-model="searchParams.end_issue_date"
        />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="3">
        <v-btn
          @click="$router.push('/pdv')"
          class="text-capitalize"
          bottom
          large
          color="primary"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs class="mt-2">
          <v-tab  class="text-capitalize"
            @click="(searchParams.status = null), select()"
            >Todos</v-tab
          >
          <v-tab class="text-capitalize"
            @click="(searchParams.status = 'sale'), select()"
            >
            <v-icon class="mr-2" small>mdi-cart</v-icon> Vendas
          </v-tab>
          <v-tab class="text-capitalize"
            @click="(searchParams.status = 'order'), select()"
          >
            <v-icon class="mr-2" small>mdi-file-edit-outline</v-icon> Pedidos
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="mt-4">
      <v-col class="mr-4" cols="9">
        <orders-list
          :loading="loading"
          :sales="sales"
          @click:row="openOrderView($event.id)"
        />
        <v-pagination
          v-model="searchParams.page"
          :length="sales.last_page"
          @input="select($event)"
          :total-visible="9"
        />
      </v-col>
      <v-col class="ml-8">
        <v-card color="grey lighten-3 pb-10" elevation="0">
          <v-card-text>
            <div class="text-right mb-3">
              <span class="text--disabled">Por Meio de Pagamento</span>
            </div>
            <div
              class="
                dashed-bottom-border
                pb-1
                mb-4
                d-flex
                justify-space-between
              "
            >
              <div><v-icon class="mr-4">mdi-cash</v-icon> Dinheiro</div>
              <div>
                <b>231,12</b>
              </div>
            </div>
            <div
              class="
                dashed-bottom-border
                pb-1
                mb-4
                d-flex
                justify-space-between
              "
            >
              <div><v-icon class="mr-4">mdi-credit-card</v-icon> Crédito</div>
              <div>
                <b>2.123,12</b>
              </div>
            </div>
            <div
              class="
                dashed-bottom-border
                pb-1
                mb-4
                d-flex
                justify-space-between
              "
            >
              <div><v-icon class="mr-4">mdi-credit-card</v-icon> Débito</div>
              <div>
                <b>123,12</b>
              </div>
            </div>
            <div
              class="
                dashed-bottom-border
                pb-1
                mb-4
                d-flex
                justify-space-between
              "
            >
              <div><v-icon class="mr-4">mdi-qrcode-scan</v-icon> Pic Pay</div>
              <div>
                <b>123,12</b>
              </div>
            </div>

            <div
              class="
                dashed-bottom-border
                pb-1
                mb-4
                d-flex
                justify-space-between
              "
            >
              <div><v-icon class="mr-4">mdi-qrcode-scan</v-icon> Pix</div>
              <div>
                <b>123,12</b>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <OrderViewDialog ref="OrderViewDialog" />
  </div>
</template>

<script>
import OrdersList from "@/components/orders/lists/OrdersList";

import OrderViewDialog from "@/components/orders/dialos/OrderViewDialog";
import { dimensions } from "vee-validate/dist/rules";
export default {
  components: {
    OrdersList,
    OrderViewDialog,
  },

  data() {
    return {
      sales: {},
      searchParams: {
        page: null,
        status: null,
      },
      loading: false,
    };
  },

  mounted() {
    this.select();
  },

  methods: {
    openOrderView(sale_id) {
      this.$refs.OrderViewDialog.open(sale_id);
    },
    handleSearchInput(text) {
      console.log(text);
    },

    async select(page = 1) {
      this.loading = true;
      this.searchParams.page = page;

      await this.$http
        .index("sale/sale", this.searchParams)
        .then((response) => {
          this.sales = response.sales;
        });

      // this.$http.index("sale/sale-report", {
      //   metric : 'net_total_sum',
      //   dimension : 'customer'
      // }).then((response) => {
      //   console.log(response)
      //  // this.salesReport = response.salesReport
      // })

      this.loading = false;
    },
  },
};
</script>

<style>
.dashed-bottom-border {
  border-bottom: dashed 1px rgba(0, 0, 0, 0.12);
}
</style>