<template>
  <div>
    <h1 class="text-center mb-4 teal--text text--lighten-4">Bem vindo !</h1>
    <v-tabs
      v-model="tab"
      class="mt-8"
      fixed-tabs
      dark
      color="primary"
      background-color="#222e3f"
    >
      <v-tab class="text-capitalize">Login</v-tab>
      <v-tab class="text-capitalize">Criar conta</v-tab>
    </v-tabs>
    <login-form v-show="tab == 0" />
    <sing-up-form v-show="tab == 1" />
  </div>
</template>
<script>

import LoginForm from "@/components/auth/forms/LoginForm.vue";
import SingUpForm from "@/components/auth/forms/SingUpForm.vue";

export default {
  components: { LoginForm, SingUpForm },
  data() {
    return {
      tab: null,
      // form: {
      //   username: null,
      //   password: null,
      // },
    };
  },

  methods: {
    // login() {
    //   this.$auth.login(this.form);
    // },
  },
};
</script>

 