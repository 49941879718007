<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="drawer"
    width="400px"
  >
    <!-- <v-card> -->
    <v-card-title>Editar Produto</v-card-title>
    <v-card-text class="mb-6">
      <v-row>
        <v-col>
          <h2>{{ form.product.name }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-number-field v-model="form.quantity" label="Quantidade" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-number-field v-model="form.price" label="Preço" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Sub Total
        </v-col>
        <v-col class="text-right">
          <h3>R$ {{ $format.decimal(subTotal) }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <app-number-field v-model="form.product_discount" label="Desconto" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          Total
        </v-col>
        <v-col class="text-right">
          <h2>R$ {{ $format.decimal(total) }}</h2>
        </v-col>
      </v-row>
      
    </v-card-text>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn class="text-capitalize mr-2" color="primary" @click="updateProduct()">Salvar</v-btn>
        <v-btn @click="dialog = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data : () => ({
    drawer : false,

    index : null,
    form : {
      product : {},
      product_variant : {},
      description : null,
      quantity : 1,
      price : null,
      product_discount : 0,
    }
  }),

  computed: {
    subTotal() {
      return this.form.quantity * this.form.price
    },
    
    total() {
      return this.subTotal - this.form.product_discount
    }
  },


  methods : {
    edit(index) {
      this.index = index
      this.loadSaleProduct(index)
      this.drawer = true
    },

    loadSaleProduct(index) {
      this.form = JSON.parse(JSON.stringify(this.$store.state.pdv.sale.sale_products[index]));
    },

    insertProduct(product, product_variant) {

    },

    insertUnregisteredProduct() {

    },

    updateProduct() {
      this.$store.commit('pdv/updateProduct', { product : this.form, index : this.index })
      this.drawer = false
    },

    addProduct() {
      this.$store.commit('pdv/addUnregisteredProduct', this.form)
    }
  }
};
</script>

<style>
</style>