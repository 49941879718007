<template>
  <div>carregando...</div>
</template>

<script>
export default {
  created() {
    if (this.$route.query.token) {
      this.login(this.$route.query.token)
    }
  },

  methods : {
    async login(token) {
      let tokenObj = {
        access_token : token,
        expires_in : 3600,
        token_type : 'bearer'
      }

      this.$auth.processToken(tokenObj)
      await this.$auth.getLoginData();
      this.$router.push("/");
    }
  }
};
</script>

<style>
</style>