<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs>
          <v-tab to="/produtos" class="text-capitalize">Produtos</v-tab>
          <v-tab to="/categorias" class="text-capitalize">Categorias</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleNewProduct()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          @click:row="handleNewProduct($event)"
          :loading="loading"
          :headers="headers"
          :items="products.data"
          :items-per-page="50"
          hide-default-footer
        >
          <!-- <template v-slot:[`item.name`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-badge color="error" icon="mdi-alert">
                  <span v-bind="attrs" v-on="on">
                    {{ item.name }}
                  </span>
                </v-badge>
              </template>
              <span>Produto em Estoque Minimo</span>
            </v-tooltip>
          </template> -->
          <template v-slot:[`item.product_variants_price`]="{ item }">
            <span v-if="item.product_variants.length > 0">{{ $format.decimal(item.product_variants[0].price) }}</span>
          </template>
          <template v-slot:[`item.stock`]="{ item }">
            {{ $format.decimal(item.product_variants[0].stock.balance) }}
          </template>
          <template v-slot:loading>Carregando.. </template>
          <template v-slot:no-data>Nenhum item encontrado</template>
        </v-data-table>
        <v-pagination
          v-model="searchParams.page"
          :length="products.last_page"
          @input="select($event)"
          :total-visible="9"
        />
      </v-col>
    </v-row>
    <base-product-form @store="select()" ref="BaseProductForm" />
  </div>
</template>

<script>
import BaseProductForm from "@/components/product/forms/BaseProductForm.vue";

export default {
  components: { BaseProductForm },

  data() {
    return {
      searchParams: { page: 1, text: null },
      loading: false,
      products: {},
      headers: [
        { text: "Nome", align: "start", value: "name", sortable: false },
        { text: "Preço", value: "product_variants_price", sortable: false, align: 'right', width: "12%" },
        { text: "Estoque", value: "stock", sortable: false, align: 'right', width: "12%" },
      ],
    };
  },
  mounted() {
    this.select();
  },

  methods: {
    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    select(page = 1) {
      this.searchParams.page = page;

      this.loading = true;
      this.$http
        .index("product/product", this.searchParams)
        .then((response) => {
          this.products = response.products;
          this.loading = false;
        });
    },

    handleNewProduct(data) {
      this.$refs.BaseProductForm.open(data);
    },
  },
};
</script>

<style>
</style>