<template>
  <div>
    <validation-observer #default="{ invalid }" ref="loginValidation">
      <form @submit.prevent>
        <validation-provider
          name="email"
          rules="required|email"
          #default="{ errors }"
        >
          <v-row class="mt-6">
            <v-col md="12">
              <v-text-field
                color="primary"
                @keyup.enter.native="login"
                v-model="form.email"
                placeholder="exemplo@exemplo.com"
                dark
                :state="errors.length > 0 ? false : null"
                :error-messages="errors[0]"
              />
            </v-col>
          </v-row>
        </validation-provider>

        <validation-provider
          name="Password"
          rules="required|min:4"
          #default="{ errors }"
        >
          <v-row class="mt-3">
            <v-col md="12">
              <app-password-field
                color="primary"
                @keyup.enter.native="login"
                v-model="form.password"
                placeholder="Sua Senha"
                dark
                :name="'password'"
                :state="errors.length > 0 ? false : null"
                :error-messages="errors[0]"
              />
            </v-col>
          </v-row>
        </validation-provider>

        <v-row class="mt-10">
          <v-col>
            <v-btn
              class="text-capitalize"
              @click="validationForm()"
              block
              color="primary"
            >
              Login
            </v-btn>
            <v-btn
              class="text-capitalize mt-2 px-0"
              @click="forgotPassword()"
              small
              color="primary"
              text
              plain
              :ripple="false"
            >
              Esqueci minha senha
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize"
              @click="googleLogin()"
              block
              color="secondary"
            >
              Login Com Google
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center teal--text text--lighten-3">
            Não possui conta? Experimente Gratis
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "./../../../utils/validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      tab: null,
      form: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },

    login() {
      this.$auth.userLogin(this.form).then(async (response) => {
        await this.$auth.getLoginData();
        this.$router.push("/");
      });
    },

    forgotPassword() {
      this.$router.push('recuperar-senha')
    },

    googleLogin() {
      this.$auth.loginWithGoogle(); // todo move to configuration
    },
  },
};
</script>

<style>
</style>