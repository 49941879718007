<template>
  <v-dialog scrollable v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <div class="d-md-flex justify-space-between" style="width: 100%">
          <div v-if="sale.customer">
            {{ sale.customer.name }}
          </div>
          <div v-else>
            Consumidor Final
          </div>
          <div>
            <b>Pedido Nº {{ sale.code }}</b>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-divider class="mt-4 mb-4" />
        <template v-for="(product, index) in sale.sale_products">
          <v-row class="mt-2" :key="index">
            <v-col cols="1">
              <b>{{ $format.decimal(product.quantity, 0) }}</b>
            </v-col>
            <v-col cols="7">
              {{ product.product_name }}
              <br />
              <small class="text--secondary">
                R$ {{ $format.decimal(product.price) }}</small
              >
            </v-col>
            <v-col cols="4" class="text-right">
              R$
              {{
                $format.decimal(
                  product.price * product.quantity - product.product_discount
                )
              }}
              <template v-if="product.product_discount > 0">
                <br />
                <small class="error--text"
                  >- R${{
                    $format.decimal(product.product_discount)
                  }}
                  Desc.</small
                >
              </template>
            </v-col>
          </v-row>
        </template>
        <v-divider class="mt-4 mb-4" />
        <v-list dense>
          <v-list-item
            v-for="(payment, paymentIndex) in sale.sale_payments"
            :key="paymentIndex"
          >
            <v-list-item-icon>
              <v-icon
                class="text--secondary"
                v-text="payment.payment_method.icon"
              />
            </v-list-item-icon>
            <v-list-item-content class="text--secondary">
              <v-list-item-title v-text="payment.payment_method.name" />
            </v-list-item-content>

            <v-list-item-action class="text--secondary">
              R$ {{ $format.decimal(payment.value) }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider class="mb-4" />
        <v-row>
          <v-col class="text-right">
            <span class="text-secondary mr-4">Subtotal: </span>
            <h3 class="d-inline">{{ $format.decimal(sale.product_total) }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <span class="text-secondary mr-4">Desconto: </span>
            <h3 class="d-inline">
              {{ $format.decimal(sale.general_discount) }}
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <span class="text-secondary mr-4">Total: </span>
            <h2 class="d-inline">{{ $format.decimal(sale.net_total) }}</h2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancelSale()" text class="text-capitalize error--text"
          >Cancelar Venda</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      sale: {
        customer: { main_address: {} },
        sale_payments: [],
        sale_products: [],
      },
    };
  },
  methods: {
    async open(id) {
      await this.$http.show("sale/sale", id).then((response) => {
        this.sale = response.sale;
        this.dialog = true;
      });
    },
    cancelSale() {
      (this.sale.payment_status = "canceled"), (this.sale.status = "canceled");

      this.$http
        .update("sale/sale", this.sale.id, this.sale)
        .then((response) => {
          this.dialog = false;
        });
    },
  },
};
</script>

<style></style>
