<template>
  <div>
    <v-row>
      <v-col class="d-flex align-center">
        <app-text-search-field @input="select()" v-model='searchParams.text' />
          <v-btn
          class="ml-2"
          fab
          text
          small
          color="primary"
          @click="type = setType.type"
        >
          <v-icon v-text="setType.icon" />
        </v-btn>
      </v-col>
    </v-row>
    <CategoryTabs  @input='select()' v-model='searchParams.category_id'/>
    <v-divider/>
    <ProductList :products="products" v-if="type == 'list'" />
    <ProductCards
      :products="products"
      v-if="type == 'cards'"
      @newProduct="handleNewProduct()"
    />
    <br/>
    <v-pagination
      v-model="searchParams.page"
      :length="products.last_page"
      @input="select($event)"
      :total-visible="9"
    />
    <BaseProductForm ref="BaseProductForm" @store="select(1)" />
  </div>
</template>

<script>
import BaseProductForm from "@/components/product/forms/BaseProductForm.vue";
import ProductList from "@/components/pdv/sections/ProductList";
import ProductCards from "@/components/pdv/sections/ProductCards";
import CategoryTabs from "../../categories/sections/CategoryTabs";

export default {
  components: { ProductList, ProductCards, BaseProductForm, CategoryTabs },

  data: () => ({
    type: "cards",

    products: [],

    searchParams: {
      page: 1,
      text: null,
      category_id: null
    },
  }),

  computed: {
    setType() {
      if (this.type == "cards") {
        let data = { icon: "mdi-view-list", type: "list" };
        return data;
      }
      if (this.type == "list") {
        let data = { icon: "mdi-cards", type: "cards" };
        return data;
      }
    },
  },

  created() {
    this.select(1);
  },

  methods: {
    select(page = 1) {
      this.searchParams.page = page;

      this.loading = true;
      this.$http
        .index("product/product", this.searchParams)
        .then((response) => {
          this.products = response.products;
          this.loading = false;
        });
    },

    handleNewProduct() {
      this.$refs.BaseProductForm.open();
    },
  },
};
</script>

<style>
</style>