<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="dialog"
    width="600px"
  >
    <!-- <v-card> -->
    <v-card-title >{{title}}</v-card-title>
    <v-card-text>
      <v-tabs>
        <v-tab class="text-capitalize">Cadastro</v-tab>
        <v-tab class="text-capitalize">Compras</v-tab>
        <v-tab class="text-capitalize">A Receber</v-tab>
      </v-tabs>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- <h3 class="mt-4 mb-8">Informações</h3> -->

        <v-row class="mt-8">
          <v-col cols="12">
            <app-text-field
              v-model="form.name"
              label="Nome"
              prepend-icon="mdi-account"
            />
          </v-col>
          <v-col cols="12">
            <app-text-field
              v-model="form.phone_wa"
              label="Whatsapp"
              prepend-icon="mdi-whatsapp"
            />
          </v-col>
          <v-col cols="12">
            <app-text-field
              v-model="form.phone"
              label="Telefone"
              prepend-icon="mdi-phone"
            />
          </v-col>
        </v-row>

        <v-list>
          <v-list-item class="px-0">
            <v-list-item-title
              ><h3 class="mt-10 mb-4">Endereço</h3></v-list-item-title
            >
            <v-list-item-action>
              <v-btn
                icon
                color="primary"
                @click="showAddress = true"
                v-if="!showAddress"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                @click="showAddress = false"
                v-if="showAddress"
              >
                <v-icon>mdi-minus-thick</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-if="showAddress">
          <v-row>
            <v-col cols="12" md="4">
              <app-text-field
                v-model="form.main_address.postal_code"
                prepend-icon="mdi-magnify"
                @click:append-outer="getZipCode()"
                label="CEP"
              />
            </v-col>
            <v-col cols="12" md="12d">
              <app-text-field
                v-model="form.main_address.address"
                label="Endereço"
                prepend-icon="mdi-map-marker"
              />
            </v-col>

            <v-col cols="12" md="6">
              <app-text-field
                v-model="form.main_address.number"
                label=" Número"
                prepend-icon="mdi-numeric"
              />
            </v-col>

            <v-col cols="12" md="6">
              <app-text-field
                v-model="form.main_address.district"
                label="Bairro"
                prepend-icon="mdi-mailbox"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" md="6">
              <app-text-field
                v-model="form.main_address.city"
                label="Cidade"
                prepend-icon="mdi-city"
              />
            </v-col>
            <v-col cols="4" md="6">
              <app-text-field
                v-model="form.main_address.state"
                label="Estado"
                prepend-icon="mdi-map"
              />
            </v-col>
            <v-col cols="12">
              <app-text-field
                v-model="form.main_address.complement"
                label="Complemento"
                prepend-icon="mdi-alphabetical-variant"
              />
            </v-col>
          </v-row>
        </template>

        <v-list>
          <v-list-item class="px-0">
            <v-list-item-title
              ><h3 class="mt-2 mb-4">
                Informações Adicionais
              </h3></v-list-item-title
            >
            <v-list-item-action>
              <v-btn
                icon
                color="primary"
                @click="showInfo = true"
                v-if="!showInfo"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                @click="showInfo = false"
                v-if="showInfo"
              >
                <v-icon>mdi-minus-thick</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <template v-if="showInfo">
          <v-row>
            <v-col cols="12" md="4">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
            <v-col cols="12" md="8">
              <app-text-field v-model="form.cnpj" label="CPF / CNPJ" prepend-icon="mdi-account-box"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <app-text-field v-model="form.email" label="Email" prepend-icon="mdi-email"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.details"
                label="Observações"
                rows="4"
                prepend-icon="mdi-alphabetical-variant"
              ></v-textarea>
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-card-text>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          @click="handleSave()"
          class="text-capitalize mr-2"
          color="primary"
          >Salvar</v-btn
        >
        <v-btn @click="dialog = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>
      

<script>
import cep from "cep-promise";

export default {
  data() {
    return {
      showAddress: false,
      showInfo: false,
      dialog: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      valid: true,
      form: {},
      baseForm: {
        code: null,
        name: null,
        phone_wa: null,
        phone: null,
        cpfCnpj: null,

        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city: null,
          state: null,
        },

        email: null,
        details: null,
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
    title(){
      if (this.isInsert) {
        return  'Cadastrar Cliente'
      }
      if (!this.isInsert) {
        return  'Atualizar Cliente'
      }
    }
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id = null) {
      this.reset();
      if (id) {
        await this.showCustomer(id);
      }
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      //this.$refs.form.resetValidation();
      //this.$refs.form.reset();
    },

    async showCustomer(id) {
      await this.$http.$get("customer/customer/" + id).then((response) => {
        this.form = response.customer;
      });
    },

    handleSave() {
      //this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("customer/customer", this.form).then((response) => {
        this.processSaved(response.customer);
      });
    },

    update() {
      this.$http
        .update("customer/customer", this.form.id, this.form)
        .then((response) => {
          console.log(response);
          this.processSaved();
        });
    },

    processSaved(customer) {
      this.$emit("store", customer);
      this.dialog = false;
    },

    getZipCode() {
      cep(this.form.main_address.postal_code)
        .then((response) => {
          this.form.main_address.city = response.city;
          this.form.main_address.address = response.street;
          this.form.main_address.district = response.neighborhood;
          this.form.main_address.state = response.state;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style scoped>
</style>