<template>
  <v-app>
    <v-main>
      <v-app-bar color="white" flat>
        <v-img
          max-height="100"
          max-width="150"
          src="https://images-platform.99static.com//564m88cxEljFbm9aPcK7MV38DBI=/200x200:1800x1800/fit-in/500x500/99designs-contests-attachments/98/98438/attachment_98438021"
        ></v-img>

        <v-spacer></v-spacer>

        <span class="mt-3 pr-4 mr-10">
          <p class="font-weight-black">CONFIGURE SEU POS</p>
        </span>

        <v-spacer></v-spacer>
        <span class="mt-3">
          <p class="muted">1 de 3</p>
        </span>
      </v-app-bar>
      <v-progress-linear height="8" :value="proggres"></v-progress-linear>

      <v-container>
        <v-col class="py-10" md="12" offset-md="3">
          <!-- <template v-if="this.step == 1">
            <v-row>
              <v-col>
                <h1>1. Dados da empresa</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="font-weight-regular">
                  Vamos começar? Digite o nome e o endereço da sua empresa para
                  se inscrever.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <v-text-field
                  dense
                  color="primary"
                  placeholder="Minha empresa"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="text-capitalize"
                  @click="setStep(2)"
                  color="primary"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </template> -->

          <template v-if="this.step == 1">
            <v-row>
              <v-col>
                <h1>1. Vamos mais longe com menos dados</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="font-weight-regular">
                  Preencha o seu CNPJ sem os pontos e traços e o sistema
                  procurará pelo restante
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="5">
                <app-cnpj-field
                  dense
                  color="primary"
                  placeholder="CNPJ"
                  @company="setCompany($event)"
                />
                <!-- </v-col>
            </v-row>

            <v-row>
              <v-col> -->
                <!-- <v-radio-group v-model="row" row>
                  <v-radio label="Mei" value="radio-1"></v-radio>
                  <v-radio label="Simples Nacional" value="radio-2"></v-radio>
                  <v-radio label="Pessoa Fisica" value="radio-3"></v-radio>
                </v-radio-group> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <v-btn
                  class="text-capitalize"
                  @click="setStep(2)"
                  color="primary"
                  :disabled="!check"
                >
                  Continuar
                </v-btn>
              </v-col>
              <v-col class="text-right" md="2" >
                <v-btn
                  text
                  class="text-capitalize"
                  @click="setStep(4)"
                  color="primary"
                >
                  pular esta etapa
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      step: 1,
      proggres: 33,
      company_data: {},
      check: false
    };
  },

  watch: {
    // sempre que a pergunta mudar, essa função será executada
    company_data: function (value) {
    
     (!!Object.keys(value).length) ? this.check = true : this.check = false
    },
  },

  methods: {
    setStep(param) {
      this.step = param;

      if (param == 1) {
        this.proggres = 33;
      }

      if (param == 2) {
        this.proggres = 66;
      }
    },

    setCompany(event) {
      this.company_data = event;
    },
  },
};
</script>

<style>
</style>